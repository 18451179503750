.toggle-tabs {
  display: flex;
  flex-direction: row;
  height: 45px;
  justify-content: center;
  margin: auto;
  max-width: 392px;
  width: 100%;

  &-container {
    width: 100%;

    &:first-child {
      border-top-left-radius: 5px;

      .toggle-tabs-button {
        border-top-left-radius: 4px;
        border-right-color: transparent;
        border-right-width: 0
      }
    }
    &:last-child {
      border-top-right-radius: 5px;

      .toggle-tabs-button {
        border-top-right-radius: 4px;
        border-left-color: transparent;
        border-left-width: 0
      }
    }
  }

  &-button {
    background-color: $white;
    border: $Brand-Black 1px solid;
    color: $Dark-Gray;
    height: 100%;
    width: 100%;

    &.selected {
      color: $white;
      background-color: $Brand-Black;
    }

    &-text {
      font-family: $National2TestRegular;
    }
  }
}
