.searchable-dropdown {
  margin: auto;
  margin-bottom: 32px;
  max-width: 392px;
  width: 100%;

  &-title {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $Dark-Gray;
    height: 24px;
    margin-bottom: 4px;
  }

  &-value {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $Dark-Gray;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    padding-right: 10px;
    height: 32px;

    .selected-value {
      font-weight: bold;
    }
  }

  .down-arrow-img {
    height: 15px;
    width: 15px;

    &.dropdown-open {
      -webkit-transform:rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  &-form {
    background-color: $Brand-Black;
    border-bottom: $Dark-Gray 1px solid;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    padding: 5px 0 10px;

    &-container {
      background-color: $Brand-Black;
      padding: 4px 16px;
      width: 100%;
    }

    .clear-text {
      border: none;
      padding: 0;
      background-color: transparent;
      margin: 0;
      width: 26px;
      height: 26px;
    }
  }

  &-input {
    background-color: $Brand-Black;
    border: none;
    color: $white;
    margin-left: 5px;
    width: 100%;

    &:focus{
      outline: none;
    }
    &:focus-visible{
      outline: none;
    }
  }

  .searchable-dropdown-input::placeholder {
    color: $white;
  }

  &-options-container {
    position: relative;
    width: 100%;

    & ul {
      padding: 0;
      z-index: 1;
      background-color: $Brand-Black;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      position: absolute;
      width: 100%;
      max-height: min(494px, 36vh);
      overflow-y: auto;
      @media #{$breakpoint-below-tablet} {
        max-height: 50vh;
      }

      &::-webkit-scrollbar {
        width: 12px;
      }

      &::-webkit-scrollbar-thumb {
        width: 5px;
        background: $white;
        border-radius: 15px;
        background-clip: content-box;
        border: 5px solid transparent;
      }

      & li {
        list-style: none;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        padding: 4px 16px;
        color: $white;

        &:hover {
          background-color: #4D4D50;
        }
      }
    }

    & .disabledOption {
      background-color: $Inactive-Gray;
      opacity: 0.3;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}
