.payment-methods {
    padding: 0px !important;

    @mixin page-heading-style {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $Brand-Black;
    }

    .payment-methods-header {
        padding: 0px !important;
        margin-bottom: 1.2rem;

        @media #{$breakpoint-below-tablet} {
            margin-top: 24px;
            margin-left: 8%;
        }

        .payment-methods-heading {
            @include page-heading-style;
            font-size: 20px;
            line-height: 22px;
            letter-spacing: 1px;
        }
    }

    .payment-methods-allCardsContainer {
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 20px;
        margin-bottom: 20px;

        @media #{$breakpoint-below-tablet} {
            flex-direction: column;
        }

        .payment-methods-singleCardContainer {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            padding: 16px 24px 16px;
            width: 317px;
            height: 210px;
            background: #F8F7F5;
            box-shadow: 0px 2px 8px rgb(0 0 0 / 5%), 0px 1px 4px rgb(0 0 0 / 5%), 0px 1px 2px rgb(0 0 0 / 5%);
            border-radius: 8px;
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            color: #202124;
            box-sizing: border-box;

            @media #{$breakpoint-below-desktop-above-tablet} {
                width: 48%;
                height: 200px;
            }

            @media #{$breakpoint-below-tablet} {
                margin: auto;
            }

            .payment-methods-singleCardContainer-firstRow {
                display: flex;
                align-items: center;
                :nth-child(2) {
                    margin-left: 20px;
                    width: 85px;
                    height: 24px;
                    font-family: $National2Regular;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 15px;
                    color: #202124;
                }
                :nth-child(2):not(:empty)::before {
                    content: '\2022  \2022  \2022  \2022  ';
                }

                :nth-child(3) {
                    margin-left: auto;
                    text-decoration: underline;
                    cursor: pointer;
                    width: 60px;
                    height: 21px;
                    font-family: $National2Regular;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 150%;
                    color: #202124;
                }
            }

            .payment-methods-singleCardContainer-secondRow {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                // height: 27px;
                font-family: $National2Regular;
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                // line-height: 150%;
                color: #202124;
            }

            .payment-methods-singleCardContainer-thirdRow {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                // display: inline-grid;

                :nth-child(1) {
                    width: 344px;
                    height: 24px;
                    font-family: $National2Regular;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 150%;
                    color: #202124;
                }

                :nth-child(2) {
                    width: 344px;
                    height: 24px;
                    font-family: $National2Regular;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 150%;
                    letter-spacing: -0.1px;
                    color: #202124;
                }
            }
        }
    }
    .no-cards-template {
        margin-bottom: 40px;
        @media #{$breakpoint-below-tablet} { 
            padding-left: 8%;
        }
    }
}
