.search-store-postal-content {
  margin: 32px auto;
  max-width: 392px;
  width: 100%;

  & .search-delivery-address {
    margin-right: 0 !important;
  
    & .input-delivery-address {
      input {
        padding-left: 11% !important;
        padding-right: 10%;
        padding-top: 2%;
        padding-bottom: 2%;
        width: 100%;
      }
    }
  }
}